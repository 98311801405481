import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';
import _isNil from 'lodash/isNil';

import {withStyles} from '@material-ui/core/styles';

import {
  ButtonCancel,
  ClientOsIcon,
  Dropdown,
  InfiniteListWithViewModelsCard,
  RenderDivOrEmpty,
  StatusIndicator,
} from '../index';

import {FULL_WIDTH_MODE_TABLE, USER_ROLES} from '../../constants';

import {RoleAvailability} from '../../containers';

import {CustomButton} from '../../material-dashboard-pro-react/components';

import {CLIENT_ACTIONS, TABS as clientsTabs} from '../../app-common/Clients/constants';

import style from './style';

const multiSelectAvailabilityMap = {
  [USER_ROLES.SERVICE_DESK]: false,
};

class ClientsTable extends Component {
  static initState = {
    isMultiSelectShown: false,
    selectedClientActionIndex: -1,
  };

  constructor(props) {
    super(props);

    this.state = ClientsTable.initState;

    this.clientActionsMap = new Map([
      [I18n.t(`clients.${clientsTabs.MANAGE}.table.clientActions.${CLIENT_ACTIONS.DISABLE}`),
        props.handleDisableByIdList],
      [I18n.t(`clients.${clientsTabs.MANAGE}.table.clientActions.${CLIENT_ACTIONS.ENABLE}`),
        props.handleEnableByIdList],
      [I18n.t(`clients.${clientsTabs.MANAGE}.table.clientActions.${CLIENT_ACTIONS.FORGET}`),
        props.handleForgetByIdList],
      [I18n.t(`clients.${clientsTabs.MANAGE}.table.clientActions.${CLIENT_ACTIONS.UNINSTALL}`),
        props.handleUninstallByIdList],
    ]);

    this.osIconCellRenderer = (row) =>
      (<ClientOsIcon iconKey={row.value} osName={row.original.osName} />);
    this.statusIndicatorCellRenderer = (rowInfo) => (
      <StatusIndicator status={`DEVICE_AGENT_${rowInfo.value}`} />
    );
  }

  get fullScreenColumns() {
    return [
      {
        Header: I18n.t(`clients.${clientsTabs.MANAGE}.table.headers.os`),
        accessor: 'osIconKey',
        Cell: this.osIconCellRenderer,
        minWidth: 50,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.MANAGE}.table.headers.clientName`),
        accessor: 'clientName',
        minWidth: 200,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.MANAGE}.table.headers.username`),
        accessor: 'username',
        minWidth: 100,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.MANAGE}.table.headers.agentStatus`),
        accessor: 'agentStatus',
        Cell: this.statusIndicatorCellRenderer,
        minWidth: 100,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.MANAGE}.table.headers.lastSync`),
        accessor: 'lastSync',
        minWidth: 200,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.MANAGE}.table.headers.profile`),
        accessor: 'profile',
        minWidth: 100,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.MANAGE}.table.headers.version`),
        accessor: 'version',
        minWidth: 75,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.MANAGE}.table.headers.wanIp`),
        accessor: 'wanIP',
        minWidth: 100,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.MANAGE}.table.headers.lanIp`),
        accessor: 'lanIP',
        minWidth: 100,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.MANAGE}.table.headers.site`),
        accessor: 'site',
        minWidth: 100,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t(`clients.${clientsTabs.MANAGE}.table.headers.policy`),
        accessor: 'policy',
        Cell: this.renderPolicyName,
        minWidth: 100,
        filterable: false,
        sortable: false,
        isShow: true,
      },
    ];
  }

  get compressedColumn() {
    return [
      {
        Header: I18n.t(`clients.${clientsTabs.MANAGE}.table.headers.clientName`),
        accessor: 'clientName',
        minWidth: 100,
        filterable: false,
        sortable: true,
        isShow: true,
      },
    ];
  }

  renderPolicyName = (rowInfo) => {
    const policyType = (!_isNil(rowInfo.original.policyType))
      ? I18n.t(`policyTypes.${rowInfo.original.policyType}`)
      : '';
    return (!_isNil(rowInfo.original.policy)) ? `${policyType} : ${rowInfo.original.policy}` : '';
  };

  onMultiSelectButtonClick = () => {
    this.setState({
      isMultiSelectShown: true,
    });
  };

  onMultiSelectCancel = () => {
    this.setState({
      isMultiSelectShown: false,
      selectedClientActionIndex: -1,
    });
  };

  handleChangeClientActionDropdown = (e) => {
    this.clientActionsMap.get([...this.clientActionsMap.keys()][e.target.value])();
    this.setState({selectedClientActionIndex: e.target.value});
  };

  additionalHeaderActions = () => {
    const {isMultiSelectShown} = this.state;
    const {
      clientsSelected,
      clientsTotal,
      selectAll,
      isBulkActionDisabled,
      classes,
      mode,
    } = this.props;

    return mode === FULL_WIDTH_MODE_TABLE ? [
      <div key="clients-fullWidthMode" className={classes.clientsTableCard__additionalHeaderActions}>
        <RoleAvailability availability={multiSelectAvailabilityMap} alternativeChild={null}>
          <RenderDivOrEmpty isShow={!isMultiSelectShown}>
            <CustomButton
              color="secondary"
              fullWidth={true}
              onClick={this.onMultiSelectButtonClick}
            >
              {I18n.t(`clients.${clientsTabs.MANAGE}.table.multiselect`)}
            </CustomButton>
          </RenderDivOrEmpty>
        </RoleAvailability>
        <RenderDivOrEmpty isShow={isMultiSelectShown} className={classes.clientsTableCard__dropdown}>
          <Dropdown
            options={[...this.clientActionsMap.keys()]}
            selectedItemIndex={this.state.selectedClientActionIndex}
            onChangeValue={this.handleChangeClientActionDropdown}
            disabled={isBulkActionDisabled}
            customFormControlClass="clientsTable__dropdown"
            customSelectClass="clientsTable__dropdownSelect"
            placeholder={I18n.t(`clients.${clientsTabs.MANAGE}.table.clientActions.placeholder`)}
          />
          <ButtonCancel onClick={this.onMultiSelectCancel} />
        </RenderDivOrEmpty>
        <div className={classes.clientsTableCard__total}>
          { isMultiSelectShown
            // eslint-disable-next-line max-len
            ? `${selectAll ? clientsTotal : clientsSelected || 0} of ${clientsTotal || 0} Total Selected`
            : `${clientsTotal || 0} Total`}
        </div>
      </div>,
    ] : [];
  };

  render() {
    const {
      classes,
      customClassNames,
      tableData,
      mode,
      selectedClientIndex,
      minRows,
      isEditMode,
      loadData,
      handleClickRow,
      dataWithSelect,
      handleChangeDataWithSelect,
      selectAll,
      handleChangeSelectAll,
    } = this.props;

    const {
      isMultiSelectShown,
    } = this.state;

    const tableClassNameObj = {
      tbody: classNames(
        classes.clientsTable__tbodyInfinite,
        {
          [classes.tbodyInfinite_noItems]: tableData.length === 0,
          [classes[customClassNames.tbody]]: !_isNil(customClassNames.tbody),
        },
      ),
      header: {
        root: classes.clientsTableCard__header,
        title: classes.clientsTableCard__title,
        action: classes.clientsTableCard__action,
      },
      card: classes.clientsTableCard,
      card__content: classes.clientsTableCard__content,
    };

    return (
      <InfiniteListWithViewModelsCard
          additionalHeaderActions={this.additionalHeaderActions()}
          tableData={tableData}
          dataWithSelect={dataWithSelect}
          selectAll={selectAll}
          handleChangeSelectAll={handleChangeSelectAll}
          handleChangeDataWithSelect={handleChangeDataWithSelect}
          title={I18n.t(`clients.${clientsTabs.MANAGE}.table.title`)}
          headersFullWidthMode={this.fullScreenColumns}
          headersCompressedMode={this.compressedColumn}
          mode={mode}
          minRows={minRows}
          fullscreen={false}
          disabled={isEditMode}
          selectable={isMultiSelectShown && mode === FULL_WIDTH_MODE_TABLE}
          entityType={I18n.t('entitiesTypes.client')}
          selectedEntityIndex={selectedClientIndex}
          className={classes.clientsTable__table}
          classNamesObj={tableClassNameObj}
          handleClickRow={handleClickRow}
          loadData={loadData}
      />
    );
  }
}

ClientsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  customClassNames: PropTypes.object,
  tableData: PropTypes.array,
  minRows: PropTypes.number,
  dataWithSelect: PropTypes.array,
  handleChangeDataWithSelect: PropTypes.func,
  isBulkActionDisabled: PropTypes.bool,
  isEditMode: PropTypes.bool,
  selectAll: PropTypes.bool,
  handleChangeSelectAll: PropTypes.func,
  handleDisableByIdList: PropTypes.func,
  handleEnableByIdList: PropTypes.func,
  handleForgetByIdList: PropTypes.func,
  handleUninstallByIdList: PropTypes.func,
  clientsSelected: PropTypes.number,
  clientsTotal: PropTypes.number,
  mode: PropTypes.string.isRequired,
  selectedClientIndex: PropTypes.number,

  loadData: PropTypes.func.isRequired,
  handleClickRow: PropTypes.func.isRequired,
};

ClientsTable.defaultProps = {
  tableData: [],
  dataWithSelect: [],
  minRows: 10,
  customClassNames: {},
  isBulkActionDisabled: true,
  isEditMode: false,
  selectAll: false,
  clientsSelected: 0,
  clientsTotal: 0,
  selectedClientIndex: null,

  handleChangeDataWithSelect: () => {},
  handleChangeSelectAll: () => {},
  handleDisableByIdList: () => {},
  handleEnableByIdList: () => {},
  handleForgetByIdList: () => {},
  handleUninstallByIdList: () => {},
};

export default withStyles(style)(ClientsTable);
