import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import _get from 'lodash/get';

import {
  CustomIconButton,
} from '../../material-dashboard-pro-react/components/index';

import {
  AccountSwitcherHeaderAction,
  ProfileHeaderAction,
  HelpAndSupportHeaderAction,
  NotificationBanner,
  RenderOrEmpty,
  OrganizationSwitcherHeaderAction,
} from '../index';

import {
  Menu,
  MoreVert,
  ViewList,
} from '../../icons';

import {COLORS} from '../../constants';

import style from './style';

function Header(props) {
  const {
    classes,
    location: _location,
    loggedAccount,
    accountInfo,
    currentOrganization,
    miniActive,
    notificationBannerData,
    userAccounts,
    userOrganizations,
  } = props;
  const organizationsAllowed = _get(accountInfo, 'organizationsAllowed', false);

  return (
    <AppBar className={classes.header}>
      <Toolbar className={classes.header__toolbar}>
        <Hidden smDown={true}>
          <div className={classes.toolbar__left}>
            <CustomIconButton
              color={COLORS.WHITE}
              onClick={props.compressSidebar}
            >
              <RenderOrEmpty isShow={miniActive}>
                <ViewList className={classes.toolbar__icon} />
              </RenderOrEmpty>
              <RenderOrEmpty isShow={!miniActive}>
                <MoreVert className={classes.toolbar__icon} />
              </RenderOrEmpty>
            </CustomIconButton>
            <h1 className={classes.header__title}>
              {I18n.t(`pages.${_location.pathname}`)}
            </h1>
          </div>
        </Hidden>
        <div className={classes.toolbar__right}>
          <Hidden smDown={true}>
            <NotificationBanner notificationData={notificationBannerData} />
          </Hidden>
          <Hidden smDown={true}>
            <AccountSwitcherHeaderAction
              loggedAccount={loggedAccount}
              switchAccount={props.switchAccount}
              userAccounts={userAccounts}
            />
          </Hidden>
          <RenderOrEmpty isShow={organizationsAllowed}>
            <Hidden smDown={true}>
              <OrganizationSwitcherHeaderAction
                currentOrganization={currentOrganization}
                switchOrganization={props.switchOrganization}
                userOrganizations={userOrganizations}
                loggedAccount={loggedAccount}
              />
            </Hidden>
          </RenderOrEmpty>
          <Hidden
            smDown={true}
            implementation="css"
          >
            <HelpAndSupportHeaderAction />
            <ProfileHeaderAction
              logout={props.logout}
              loggedAccount={loggedAccount}
              accountInfo={accountInfo}
            />
          </Hidden>
          <Hidden mdUp={true}>
            <IconButton
              className={classes.toolbar__icon}
              onClick={props.handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </div>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loggedAccount: PropTypes.object.isRequired,
  accountInfo: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  miniActive: PropTypes.bool.isRequired,
  notificationBannerData: PropTypes.object.isRequired,
  userAccounts: PropTypes.array.isRequired,
  userOrganizations: PropTypes.array.isRequired,

  compressSidebar: PropTypes.func.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  switchAccount: PropTypes.func.isRequired,
  switchOrganization: PropTypes.func.isRequired,
};

Header.defaultProps = {
  currentOrganization: null,
};

export default withStyles(style)(Header);
