import React, {useEffect, useRef, useState} from 'react';
import {Card, CardContent, IconButton, TextField, withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import ReactTable from 'react-table';
import classNames from 'classnames';
import ForwardIcon from '@material-ui/icons/Forward';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import {CustomButton} from '../../material-dashboard-pro-react/components';
import Dropdown from '../Dropdown/Dropdown';

import style from './style';

/**
 * Table for configured groups
 * @param {groups} groups - array of groups. Each group must have `name` and `policyId` fields
 * @param {policies} policies - array of policies. Each policy must have `name` and `id` fields
 * @param {onNewClick} onNewClick - callback on clicking 'new group'
 * @param {onAddManualGroup} onAddManualGroup - callback on creating manual group.
 * Takes group name as a parameter
 * @param {isCreatingManualGroup} isCreatingManualGroup - if true, show editable group
 * @param {onGroupsChange} onGroupsChange
 * - callback on groups change. Has changed groups array as a parameter
 * @param {disabled} disabled - if disabled, groups can't be configured
 */
function ConfiguredGroups({classes,
  groups,
  policies,
  onNewClick,
  onGroupsChange,
  onAddManualGroup,
  disabled,
  isCreatingManualGroup}) {
  const [preparedGroups, setPreparedGroups] = useState(groups ?? []);

  const textFieldRef = useRef();

  /**
   * Check if group at index is editable group.
   * Editable groups should be handled separately from others.
   * @returns true if group at index is editable
   */
  const isIndexOfEditableGroup = (groupIndex) => groupIndex >= groups.length;

  /**
   * Changes priority of a group
   * @param {index} index - index of a group to change
   * @param {change} change - where to move group: -1 to decrease priority, 1 to increase
   */
  const changeGroupPriority = (index, change) => {
    if (isIndexOfEditableGroup(index)) {
      return;
    }
    const newGroups = [...groups];
    const newIndex = index - change;
    if (newIndex >= newGroups.length || newIndex < 0) {
      return;
    }
    newGroups.splice(newIndex, 0, newGroups.splice(index, 1)[0]);
    onGroupsChange(newGroups);
  };

  const deleteGroup = (index) => {
    if (isIndexOfEditableGroup(index)) {
      onAddManualGroup(null);
    } else {
      onGroupsChange(groups.filter((_, i) => i !== index));
    }
  };

  const handlePolicyChange = (groupIndex, newPolicyIndex) => {
    const policyId = policies[newPolicyIndex].id;
    const newGroups = [...groups];
    newGroups[groupIndex].policyId = policyId;
    onGroupsChange(newGroups);
  };

  const renderGroup = (groupName, isEditable) => (isEditable
    ? (
      <TextField
        inputRef={textFieldRef}
        inputProps={{
          onBlur: () => onAddManualGroup(textFieldRef.current.value),
          onKeyDown: (e) => e.key === 'Enter' && onAddManualGroup(textFieldRef.current.value),
        }}
      />
    )
    : (<span className={classes.group}>{groupName}</span>));

  const renderActions = (index) => {
    if (disabled) {
      return null;
    }
    return (
      <div className={classes.actionsContainer}>
        <IconButton
        className={
          classNames(classes.actionsContainer__button, classes.actionsContainer__button_up)
        }
        onClick={() => changeGroupPriority(index, 1)}
        >
          <ForwardIcon fontSize="small" />
        </IconButton>
        <IconButton
        className={
          classNames(classes.actionsContainer__button, classes.actionsContainer__button_down)
        }
        onClick={() => changeGroupPriority(index, -1)}
        >
          <ForwardIcon fontSize="small" />
        </IconButton>
        <IconButton
        className={classes.actionsContainer__button}
        onClick={() => deleteGroup(index)}
        >
          <DeleteForeverIcon fontSize="small" />
        </IconButton>
      </div>
    );
  };

  const renderPolicies = (group, index) => {
    const policyIndex = policies.findIndex((policy) => group.policyId === policy.id);
    return (
      <Dropdown
        dropdownName="policy"
        fullWidth={true}
        onChangeValue={(e) => handlePolicyChange(index, e.target.value)}
        selectedItemIndex={policyIndex}
        options={policies.map((policy) => policy.name)}
        disabled={disabled || isIndexOfEditableGroup(index)}
      />
    );
  };

  const columns = [
    {
      Header: I18n.t('users.usersConfigurer.persona.directoryServices.group'),
      accessor: 'name',
      Cell: (cell) => renderGroup(cell.value, !!cell.original.isEditable),
    },
    {
      Header: I18n.t('users.usersConfigurer.persona.directoryServices.policy'),
      Cell: (row) => renderPolicies(row.original, row.index),
    },
    {
      Header: I18n.t('users.usersConfigurer.persona.directoryServices.priority'),
      Cell: (row) => row.index + 1,
      width: 65,
      className: classes.table__cell_center,
    },
    {
      Cell: (row) => renderActions(row.index),
      width: 100,
    },
  ];

  useEffect(() => {
    if (isCreatingManualGroup) {
      setPreparedGroups([...groups, {name: '', isEditable: true}]);
    } else {
      setPreparedGroups(groups);
    }
  }, [isCreatingManualGroup, groups]);

  return (
    <Card className={classes.mainContainer}>
      <CardContent>
        <div className={classes.title}>
          <span>
            <b>
              {I18n.t('users.usersConfigurer.persona.directoryServices.configuredGroups')}
            </b>
          </span>
          <CustomButton onClick={onNewClick} disabled={disabled}>
            <span>{I18n.t('users.usersConfigurer.persona.directoryServices.newGroup')}</span>
          </CustomButton>
        </div>
        {/* @ts-ignore-next-line */}
        <ReactTable
          className={classes.table}
          data={preparedGroups}
          columns={columns}
          filterable={false}
          sortable={false}
          showPagination={false}
          resizable={false}
        />
      </CardContent>
    </Card>
  );
}

ConfiguredGroups.propTypes = {
  classes: PropTypes.object.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
  })).isRequired,
  policies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  onNewClick: PropTypes.func.isRequired,
  onAddManualGroup: PropTypes.func.isRequired,
  isCreatingManualGroup: PropTypes.bool,
  onGroupsChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ConfiguredGroups.defaultProps = {
  disabled: false,
  isCreatingManualGroup: false,
};

export default withStyles(style)(ConfiguredGroups);
