import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {I18n} from 'react-redux-i18n';
import {v4 as uuid} from 'uuid';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';

import {
  Manager,
  Popper,
  Target,
} from 'react-popper';

import {withStyles} from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';

import {CustomInput} from '../../material-dashboard-pro-react/components/index';

import {ButtonExpandLessMore} from '../index';
import {ORGANIZATION_OPERATOR_PERMISSIONS, ROLES_AVAILABILITY} from '../../constants';

import style from './style';

const ALL_ORGS_VIEW_ID = 'all';
const MAX_ORG_NAME_LENGTH = 20;

class OrganizationSwitcherHeaderAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      organizations: [],
      currentOrganization: this.allOrganizationsItem,
    };
  }

  componentDidMount() {
    this.updateOrganizationsInLocalState();
  }

  componentDidUpdate(prevProps) {
    if (
      !_isEqual(prevProps.userOrganizations, this.props.userOrganizations)
      || !_isEqual(prevProps.currentOrganization, this.props.currentOrganization)
    ) {
      this.updateOrganizationsInLocalState();
    }
  }

  get allOrganizationsItem() {
    return {
      id: ALL_ORGS_VIEW_ID,
      name: I18n.t('headerUser.userOrganizations.all'), // TODO: add VIEWER permission?
    };
  }

  getOrganizationOptions = (organizations, role) => [
    ...ROLES_AVAILABILITY.viewAllOrganizations[role] ? [this.allOrganizationsItem] : [],
    ...organizations,
  ];

  updateOrganizationsInLocalState = () => {
    const {userOrganizations, currentOrganization, loggedAccount, switchOrganization} = this.props;

    const selectedOrgId = _get(currentOrganization, 'id', ALL_ORGS_VIEW_ID);

    let selectedOrganization;

    if (!ROLES_AVAILABILITY.viewAllOrganizations[loggedAccount.role]
        && _isEqual(selectedOrgId, ALL_ORGS_VIEW_ID)) {
      // Prioritizing organizations with manager access, but if not found, look for viewer
      const orgForOperator = userOrganizations.find(
        (org) => _isEqual(org.permission, ORGANIZATION_OPERATOR_PERMISSIONS.MANAGER),
      ) ?? userOrganizations.find(
        (org) => _isEqual(org.permission, ORGANIZATION_OPERATOR_PERMISSIONS.VIEWER),
      );
      selectedOrganization = _cloneDeep(orgForOperator);
      switchOrganization(orgForOperator);
    } else {
      selectedOrganization = _cloneDeep(userOrganizations.find((organization) =>
        _isEqual(organization.id, selectedOrgId)));
    }

    this.setState({
      organizations: this.getOrganizationOptions(userOrganizations, loggedAccount.role),
      currentOrganization: selectedOrganization || this.allOrganizationsItem,
    });
  };

  searchOrganizations = (e) => {
    const {userOrganizations} = this.props;

    const searchText = e.target.value.toLowerCase();
    if (searchText !== '') {
      this.setState({
        organizations: this.getOrganizationOptions(
          userOrganizations.filter((organization) =>
            organization.name.toLowerCase().includes(searchText)),
        ),
      });
    } else {
      this.setState({
        organizations: this.getOrganizationOptions(userOrganizations),
      });
    }
  };

  handleSwitchOrganization = (organization) => {
    const organizationToSwitch = !_isEqual(organization.id, this.allOrganizationsItem.id)
      ? organization
      : null;

    this.props.switchOrganization(organizationToSwitch).then(() => {
      this.setState({currentOrganization: organization});
    });
    this.handleClose();
  };

  handleClick = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleClose = () => {
    this.setState({isOpen: false});
  };

  renderOrganizationsList = () => {
    const {
      classes,
      currentOrganization,
    } = this.props;
    const requiredOrganizationId = _get(currentOrganization, 'id', this.allOrganizationsItem.id);

    return this.state.organizations.sort((org1, org2) => {
      const allOrganizationsItem = this.allOrganizationsItem;
      if (org1.name === allOrganizationsItem.name) return -1;
      if (org2.name === allOrganizationsItem.name) return 1;
      return org1.name.localeCompare(org2.name);
    }).map((organization) => {
      const isCurrentOrganization = _isEqual(requiredOrganizationId, organization.id);

      return (
        <MenuItem
          className={classNames(
            classes.dropdown__item,
            {
              [classes.dropdown_item_currentOrganization]: isCurrentOrganization,
            },
          )}
          key={`org-${organization.id}`}
          onClick={() => this.handleSwitchOrganization(organization)}
        >
          <div
            className={classes.dropdownItem__organizationName}
            title={organization.name}
          >
            {organization.name}
          </div>
        </MenuItem>
      );
    });
  };

  processOrganizationName = (orgName) => {
    if (orgName.length > MAX_ORG_NAME_LENGTH) {
      return `${orgName.substr(0, MAX_ORG_NAME_LENGTH)}...`;
    }
    return orgName;
  };

  render() {
    const {
      classes,
      placement,
    } = this.props;
    const {
      currentOrganization,
      isOpen,
    } = this.state;

    const managerStyle = {display: 'inline-block'};
    const growStyle = {transformOrigin: '0 0 0'};
    const searchInputProps = {
      formControl: {
        fullWidth: true,
      },
      input: {
        placeholder: 'Search',
        onChange: this.searchOrganizations,
      },
    };

    const buttonExpandLessMoreStyle = {
      header: {
        color: 'primaryNoBackground',
        customClasses: 'organizationSwitcher__showHideOrgListButton_header',
      },
      sidebar: {
        color: 'whiteNoBackground',
        customClasses: 'organizationSwitcher__showHideOrgListButton_sidebar',
      },
    };

    return (
      <ClickAwayListener onClickAway={this.handleClose}>
        <Manager style={managerStyle}>
          <Target>
            <ButtonExpandLessMore
              color={buttonExpandLessMoreStyle[placement].color}
              customClasses={classNames(
                'organizationSwitcher__showHideOrgListButton',
                [buttonExpandLessMoreStyle[placement].customClasses],
              )}
              isExpandLess={isOpen}
              onClick={this.handleClick}
              text={this.processOrganizationName(currentOrganization.name)}
            />
          </Target>
          <Popper
            placement="bottom-start"
            eventsEnabled={isOpen}
            className={classNames(
              classes.popper_responsive,
              {
                [classes.popper_close]: !isOpen,
              },
            )}
          >
            <Grow
              in={isOpen}
              id={uuid()}
              style={growStyle}
            >
              <Paper className={classes.dropdown}>
                <div
                  className={classNames(
                    classes.dropdown__header,
                    classes.dropdown__header_organizationSwitcher,
                  )}
                >
                  <CustomInput
                    formControlProps={searchInputProps.formControl}
                    inputProps={searchInputProps.input}
                  />
                </div>
                <MenuList
                  className={classes.action__menuList}
                  role="menu"
                >
                  {this.renderOrganizationsList()}
                </MenuList>
              </Paper>
            </Grow>
          </Popper>
        </Manager>
      </ClickAwayListener>
    );
  }
}

OrganizationSwitcherHeaderAction.propTypes = {
  classes: PropTypes.object.isRequired,
  placement: PropTypes.oneOf(['header', 'sidebar']),
  currentOrganization: PropTypes.object,
  switchOrganization: PropTypes.func.isRequired,
  userOrganizations: PropTypes.array.isRequired,
  loggedAccount: PropTypes.object.isRequired,
};

OrganizationSwitcherHeaderAction.defaultProps = {
  placement: 'header',
  currentOrganization: null,
};
export default withStyles(style)(OrganizationSwitcherHeaderAction);
