import React from 'react';
import {I18n} from 'react-redux-i18n';
import EntityEditingCard from '../EntityEditingCard/EntityEditingCard';
import {ACCESS_RIGHTS} from '../../constants';
import {FallbackDNSEditForm} from '../../containers';

type FallbackDNSDetailsProps = {
  isEditMode: boolean,
  isValid: boolean,
  onCancel: () => void,
  onBack: () => void,
  onDelete: () => void,
  onEdit: () => void,
  onSave: () => void,
};

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.SITE_FALLBACK_EDIT],
  buttonDelete: [ACCESS_RIGHTS.SITE_FALLBACK_DELETE],
};

export function FallbackDNSDetails({isEditMode,
  isValid,
  onCancel,
  onBack,
  onDelete,
  onEdit,
  onSave}: FallbackDNSDetailsProps) {
  return (
    <EntityEditingCard
      title={I18n.t('campusPage.fallbackDNSTab.detailsTitle')}
      entityType={I18n.t('entitiesTypes.fallbackDNS')}
      isEditMode={isEditMode}
      isTherePathBack={true}
      isThereDeleteBlock={true}
      isThereEditBlock={true}
      isValidEntity={isValid}
      handleClickButtonBack={onBack}
      handleClickButtonCancel={onCancel}
      handleClickButtonDelete={onDelete}
      handleClickButtonEdit={onEdit}
      handleClickButtonSave={onSave}
      customCardClass="card_networks"
      customCardContentClass="card__content_networks"
      accessRights={rightAvailabilityMap}
      content={
        <FallbackDNSEditForm />
      }
    />
  );
}
