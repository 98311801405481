import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {I18n} from 'react-redux-i18n';
import {withStyles, WithStyles} from '@material-ui/core';

import {changeEditModeAndFallbackDNSState, FALLBACK_DNS_SETTINGS_REDUCER,
  setFailOpenEnabled,
  setSelectedUpstreamId} from './slice';
import {getFallbackDnsSettings, getUpstreamServices, saveFallbackDNS} from './action';
import {FallbackDNSServer, UpstreamService} from './types';
import {ACCESS_RIGHTS, STATES_ENTITY} from '../../constants';
import {changeEditModeAndFallbackDNSHostsState} from '../FallbackDNSHosts/slice';

import {ButtonExpandLessMore,
  RenderOrEmpty,
  Switch,
  Dropdown,
  EntityEditingCard} from '../../components';
import {FallbackDNSHosts} from '..';

import {style} from './style';

type FallbackDNSSettingsProps = WithStyles<typeof style>

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.SITE_FALLBACK_EDIT],
};

export function FallbackDNSSettings({classes}: FallbackDNSSettingsProps) {
  const dispatch = useDispatch();

  const [showUpstream, setShowUpstream] = useState(false);

  const campus = useSelector((store: any) => store.campusReducer.selectedCampus);

  const isEditMode = useSelector((store: any) => store[FALLBACK_DNS_SETTINGS_REDUCER].isEditMode);

  const servers: FallbackDNSServer[] =
    useSelector((store: any) => store[FALLBACK_DNS_SETTINGS_REDUCER].servers);
  const upstreams: UpstreamService[] =
    useSelector((store: any) => store[FALLBACK_DNS_SETTINGS_REDUCER].upstreams);
  const selectedUpstreamId =
    useSelector((store: any) => store[FALLBACK_DNS_SETTINGS_REDUCER].selectedUpstreamId);
  const failOpenEnabled =
    useSelector((store: any) => store[FALLBACK_DNS_SETTINGS_REDUCER].failOpenEnabled);

  const upsertFallbackDNSSettings = () => {
    saveFallbackDNS({
      locationId: campus.id,
      enabled: failOpenEnabled,
      selectedUpstreamId,
      servers,
    })(dispatch);
    dispatch(changeEditModeAndFallbackDNSState({
      isEditMode: false,
      state: STATES_ENTITY.NONE,
    }));
  };

  const handleEdit = () => {
    dispatch(changeEditModeAndFallbackDNSState({
      isEditMode: true,
      state: STATES_ENTITY.EDITING,
    }));
  };

  const handleCancel = () => {
    dispatch(changeEditModeAndFallbackDNSState({
      isEditMode: false,
      state: STATES_ENTITY.CANCELED,
    }));
    dispatch(changeEditModeAndFallbackDNSHostsState({
      isEditMode: false,
      state: STATES_ENTITY.CANCELED,
    }));
  };

  const getDropdown = () => {
    const selectedIndex = upstreams.findIndex((u) => u.id === selectedUpstreamId);

    return (
      <Dropdown
        fullWidth={true}
        label={I18n.t('campusPage.fallbackDNSTab.upstreamService')}
        onChangeValue={(e: React.ChangeEvent<HTMLInputElement>) =>
          dispatch(setSelectedUpstreamId(
            upstreams.at(Number(e.target.value))?.id ?? '',
          ))}
        selectedItemIndex={selectedIndex < 0 ? 0 : selectedIndex}
        options={upstreams.map((m) => m.name)}
        disabled={!isEditMode}
      />
    );
  };

  const getContent = () => (
    <div className={classes.upstreamContainer}>
      <div className={classes.upstreamContainer_item__dropdown}>
        {getDropdown()}
      </div>
      <div className={classes.upstreamContainer_item__switch}>
        <Switch
            name="failOpen"
            label={I18n.t('campusPage.fallbackDNSTab.failOpen')}
            labelFormClassName="formControl_oneLineReversedLeftAligned"
            labelClassName="switch__label_oneLine"
            isChecked={failOpenEnabled}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(setFailOpenEnabled(e.target.checked))}
            disabled={!isEditMode}
        />
      </div>
      <FallbackDNSHosts />
    </div>
  );

  useEffect(() => {
    getUpstreamServices()(dispatch);
    getFallbackDnsSettings(campus.id)(dispatch);
  }, []);

  return (
    <div>
      <ButtonExpandLessMore
        customClasses="campusRelays__buttonShowHideUpstreams"
        text={I18n.t('campusPage.fallbackDNSTab.title')}
        isExpandLess={showUpstream}
        onClick={() => setShowUpstream(!showUpstream)}
      />
      <RenderOrEmpty isShow={showUpstream}>
        <EntityEditingCard
          entityType={I18n.t('entitiesTypes.fallbackDNS')}
          title={I18n.t('campusPage.fallbackDNSTab.title')}
          isThereEditBlock={true}
          isEditMode={isEditMode}
          handleClickButtonCancel={handleCancel}
          handleClickButtonEdit={handleEdit}
          handleClickButtonSave={upsertFallbackDNSSettings}
          customCardClass="card_campusInfo"
          customCardContentClass="card__content_campusInfo"
          accessRights={rightAvailabilityMap}
          content={getContent()}
        />
      </RenderOrEmpty>
    </div>
  );
}

export default withStyles(style)(FallbackDNSSettings);
