const style = {
  entityWithModesCard__tableBody: {
    '@media(min-height: 850px)': {
      height: 'calc(100vh - 240px)',
      marginBottom: '20px',
    },
  },
  entityWithModesCard_fallbackDNSHosts: {
    boxShadow: 'none !important',
  },
};

export default style;
