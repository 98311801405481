import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import {withStyles} from '@material-ui/core';

import {
  ContainerWithListAndForm,
  MessageBox,
  RelayDetails,
  RelayTable,
} from '../../components/index';

import {
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  COMPRESSED_MODE_TABLE as COMPRESSED,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM,
  STATES_ENTITY,
  RELAY_STATUSES,
} from '../../constants';

import {
  getNetworksByCampusId,
} from '../CampusNetworksTab/action';

import {
  getRelays,
  updateRelayData,
  deleteRelay,
  setSelectedRelayIndex,
  resetSelectedRelayIndex,
  changeEditModeAndRelayState,
  setSelectedRelayTableRowData,
  resetSelectedRelayTableRowData,
  adoptRelay,
} from './action';

import {getRelayTableData, getRelayTableRowData} from './service';
import FallbackDNSSettings from '../FallbackDNSSettings/FallbackDNSSettings';

import {style} from './style';

const initialLocalState = {
  modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
  tableMode: FULL_WIDTH,
};

class CampusRelaysTab extends Component {
  static shouldUpdateNetworksAndRelays(prevProps, props) {
    return !_isEqual(prevProps.campus.id, props.campus.id)
      && !_isNil(props.campus.id);
  }

  constructor(props) {
    super(props);
    this.state = initialLocalState;
    this.timer = null;
  }

  componentDidMount() {
    const {campus} = this.props;
    this.props.getRelays(campus.id);
    this.props.getNetworksByCampusId(campus.id);
    this.setRelaysAutoUpdate();
  }

  componentDidUpdate(prevProps) {
    if (CampusRelaysTab.shouldUpdateNetworksAndRelays(prevProps, this.props)) {
      this.hideRelayDetails();
      this.props.getNetworksByCampusId(this.props.campus.id);
    }
  }

  componentWillUnmount() {
    this.props.resetSelectedRelayIndex();
    this.props.resetSelectedRelayTableRowData();
    this.stopRelaysAutoUpdate();
  }

  getContainerItemByModeShowingContainer = (modeShowingContainer) => {
    let containerItem = null;
    const {editMode} = this.props;

    switch (modeShowingContainer) {
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN:
        containerItem = null;
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS:
        containerItem = (
          <RelayDetails
            handleClickButtonBackInRelayList={this.hideRelayDetails}
            handleClickButtonCancelEditRelay={this.handleClickButtonCancelEditRelay}
            handleClickButtonDeleteRelay={this.handleClickButtonDeleteRelay}
            handleClickButtonEditRelay={this.handleClickButtonEditRelay}
            handleClickButtonSaveRelay={this.handleClickButtonSaveRelay}
            editMode={editMode}
          />
        );
        break;
      default:
        break;
    }

    return containerItem;
  };

  adoptRelay = (e, relayId) => {
    e.stopPropagation();
    this.props.adoptRelay(relayId);
  };

  getRelayList = () => {
    const {tableMode} = this.state;
    const {relays, networks, selectedRelayIndex} = this.props;

    return (
      <RelayTable
          mode={tableMode}
          tableData={getRelayTableData(relays, networks)}
          selectedRelayIndex={selectedRelayIndex}
          onRowClick={this.showRelayDetails}
          adoptRelay={this.adoptRelay}
      />
    );
  };

  setRelaysAutoUpdate = () => {
    if (_isNil(this.timer)) {
      this.timer = setInterval(() => this.props.getRelays(this.props.campus.id), 5000);
    }
  };

  stopRelaysAutoUpdate = () => {
    clearInterval(this.timer);
  };

  handleClickButtonCancelEditRelay = () => {
    this.props.changeEditModeAndRelayState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonEditRelay = () => {
    this.props.changeEditModeAndRelayState(true, STATES_ENTITY.EDITING);
  };

  handleClickButtonSaveRelay = () => {
    this.props.updateRelayData({
      enabled: this.props.editableRelayInfo.enabled,
      adopted: this.props.editableRelayInfo.adopted,
      id: this.props.editableRelayInfo.id,
    });
  };

  handleClickButtonDeleteRelay = () => {
    const {
      relays,
      selectedRelayIndex,
    } = this.props;
    if (!relays || !relays.length) {
      this.hideRelayDetails();
      return;
    }

    this.props.deleteRelay(relays[selectedRelayIndex].id)
      .then((isSuccess) => {
        if (isSuccess) {
          this.hideRelayDetails();
        }
      });
  };

  showRelayDetails = (selectedRelayIndex) => {
    const {relays} = this.props;
    this.stopRelaysAutoUpdate();
    if (!relays || !relays.length) {
      this.hideRelayDetails();
      return;
    }

    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS,
      tableMode: COMPRESSED,
    });

    this.props.setSelectedRelayIndex(selectedRelayIndex);

    this.props.setSelectedRelayTableRowData(getRelayTableRowData(
      this.props.relays[selectedRelayIndex],
      this.props.networks,
    ));
  };

  hideRelayDetails = () => {
    this.setState(initialLocalState);
    this.props.resetSelectedRelayIndex();
    this.props.resetSelectedRelayTableRowData();
    this.setRelaysAutoUpdate();
    this.props.getRelays(this.props.campus.id);
  };

  render() {
    const {modeShowingContainer} = this.state;
    const {networkAgentAllowed, classes} = this.props;

    if (networkAgentAllowed) {
      return (
        <div className={classes.container}>
          <FallbackDNSSettings />
          <ContainerWithListAndForm
            list={this.getRelayList()}
            form={this.getContainerItemByModeShowingContainer(modeShowingContainer)}
          />
        </div>
      );
    }
    return (
      <MessageBox
        message={I18n.t('campusPage.relaysTab.messages.relayUsingNotAllowed')}
      />
    );
  }
}

CampusRelaysTab.propTypes = {
  classes: PropTypes.object.isRequired,
  relays: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    networkId: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    lastConfigUpdatingAt: PropTypes.string,
    status: PropTypes.oneOf(Object.keys(RELAY_STATUSES)).isRequired,
  })).isRequired,
  selectedRelayIndex: PropTypes.number.isRequired,
  editMode: PropTypes.bool.isRequired,

  editableRelayInfo: PropTypes.shape({
    adopted: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    network: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    lastConfigUpdatingAt: PropTypes.string,
    status: PropTypes.oneOf(Object.keys(RELAY_STATUSES)).isRequired,
  }).isRequired,

  networks: PropTypes.array.isRequired,

  campus: PropTypes.object.isRequired,

  getRelays: PropTypes.func.isRequired,
  updateRelayData: PropTypes.func.isRequired,
  deleteRelay: PropTypes.func.isRequired,
  setSelectedRelayIndex: PropTypes.func.isRequired,
  resetSelectedRelayIndex: PropTypes.func.isRequired,
  setSelectedRelayTableRowData: PropTypes.func.isRequired,
  resetSelectedRelayTableRowData: PropTypes.func.isRequired,
  changeEditModeAndRelayState: PropTypes.func.isRequired,
  networkAgentAllowed: PropTypes.bool.isRequired,

  getNetworksByCampusId: PropTypes.func.isRequired,
  adoptRelay: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  relays: state.campusRelaysTabReducer.relays,
  selectedRelayIndex: state.campusRelaysTabReducer.selectedRelayIndex,
  editMode: state.campusRelaysTabReducer.editMode,
  networkAgentAllowed: state.campusRelaysTabReducer.networkAgentAllowed,

  editableRelayInfo: state.relayEditFormReducer.editableRelayInfo,

  networks: state.campusNetworksTabReducer.networks,

  campus: state.campusReducer.selectedCampus,
});

const mapDispatchToProps = (dispatch) => ({
  getRelays: bindActionCreators(getRelays, dispatch),
  updateRelayData: bindActionCreators(updateRelayData, dispatch),
  deleteRelay: bindActionCreators(deleteRelay, dispatch),
  setSelectedRelayIndex: bindActionCreators(setSelectedRelayIndex, dispatch),
  resetSelectedRelayIndex: bindActionCreators(resetSelectedRelayIndex, dispatch),
  setSelectedRelayTableRowData: bindActionCreators(setSelectedRelayTableRowData, dispatch),
  resetSelectedRelayTableRowData: bindActionCreators(resetSelectedRelayTableRowData, dispatch),
  changeEditModeAndRelayState: bindActionCreators(changeEditModeAndRelayState, dispatch),

  getNetworksByCampusId: bindActionCreators(getNetworksByCampusId, dispatch),
  adoptRelay: bindActionCreators(adoptRelay, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(CampusRelaysTab)),
);
