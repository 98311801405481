import React from 'react';
import {I18n} from 'react-redux-i18n';

import EntityCreatingCard from '../EntityCreatingCard/EntityCreatingCard';
import {FallbackDNSEditForm} from '../../containers';

type FallbackDNSServerCreatingProps = {
  onCancel: () => void,
  onSave: () => void,
  isValid: boolean,
};

export function FallbackDNSServerCreating({
  onCancel,
  onSave,
  isValid,
}: FallbackDNSServerCreatingProps) {
  return (
    <EntityCreatingCard
      title={I18n.t('campusPage.fallbackDNSTab.createServer')}
      handleClickButtonCancel={onCancel}
      handleClickButtonSave={onSave}
      isValidEntity={isValid}
      customCardClass="card_networks"
      customCardContentClass="card__content_networks"
      content={
        <FallbackDNSEditForm />
      }
    />
  );
}
