import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {STATES_ENTITY} from '../../constants';
import {ChangeEditModeAndState,
  FallbackDNSServer,
  FallbackDNSSettings,
  UpstreamService} from './types';

type InitialState = {
  isEditMode: boolean,
  fallbackDNSState: string,
  entityValid: boolean,
  selectedUpstreamId: string,
  upstreams: UpstreamService[],
  failOpenEnabled: boolean,
  servers: FallbackDNSServer[]
}

export const FALLBACK_DNS_SETTINGS_REDUCER = 'fallbackDNSSettingsReducer';

const initialState: InitialState = {
  isEditMode: false,
  fallbackDNSState: STATES_ENTITY.NONE,
  entityValid: false,

  upstreams: [],
  selectedUpstreamId: '',
  servers: [],
  failOpenEnabled: false,
};

const fallbackDNSSettingsSlice = createSlice({
  name: FALLBACK_DNS_SETTINGS_REDUCER,
  initialState,
  reducers: {
    changeEditModeAndFallbackDNSState(state, action: PayloadAction<ChangeEditModeAndState>) {
      state.isEditMode = action.payload.isEditMode;
      state.fallbackDNSState = action.payload.state;
    },
    changeFallbackDNSValid(state, action: PayloadAction<boolean>) {
      state.entityValid = action.payload;
    },
    setFallbackDNSSettings(state, action: PayloadAction<FallbackDNSSettings>) {
      state.servers = action.payload.servers;
      state.selectedUpstreamId = action.payload.selectedUpstreamId;
      state.failOpenEnabled = action.payload.enabled;
    },
    setFallbackDNSServers(state, action: PayloadAction<FallbackDNSServer[]>) {
      state.servers = action.payload;
    },
    setUpstreamServices(state, action: PayloadAction<UpstreamService[]>) {
      state.upstreams = action.payload;
    },
    setSelectedUpstreamId(state, action: PayloadAction<string>) {
      state.selectedUpstreamId = action.payload;
    },
    setFailOpenEnabled(state, action: PayloadAction<boolean>) {
      state.failOpenEnabled = action.payload;
    },
  },
});

export const {changeEditModeAndFallbackDNSState,
  changeFallbackDNSValid,
  setFallbackDNSServers,
  setFallbackDNSSettings,
  setUpstreamServices,
  setSelectedUpstreamId,
  setFailOpenEnabled} = fallbackDNSSettingsSlice.actions;

export default {name: FALLBACK_DNS_SETTINGS_REDUCER, reducer: fallbackDNSSettingsSlice.reducer};
