import {combineReducers} from 'redux';
import {i18nReducer} from 'react-redux-i18n';

import authReducer from './auth';
import userAccountsReducer from './userAccounts';
import userOrganizationsReducer from './userOrganizations';

import {types as authTypes} from '../actions/auth/actionTypes';
import {types as userAccountsTypes} from '../actions/userAccounts/actionTypes';

import {
  operatorLayoutReducer as scoutDnsOperatorLayoutReducer,
} from '../scout-dns/layouts/index';
import {
  operatorLayoutReducer as oemOperatorLayoutReducer,
} from '../oem/layouts/index';

import {
  dashboardReducer as scoutDnsDashboardReducer,
} from '../scout-dns/app';
import {
  dashboardReducer as oemDashboardReducer,
} from '../oem/app';

import {
  campusReducer,
  blockPageReducer,
  singleSignOnReducer,
  ipListReducer,
  logReducer,
  policyReducer,
  networkReducer,
  resetPasswordReducer,
  updatePasswordReducer,
  accessManagementReducer,
  apiAccessManagementReducer,
  notificationsManagementReducer,
  subscriptionReducer,
  insightsReducer,
  organizationsReducer,
} from '../app-common';

import {
  changePasswordReducer,
  campusEditFormReducer,
  campusLocalDomainsTabReducer,
  campusLocalForwardingTabReducer,
  campusNetworksTabReducer,
  campusSubnetsTabReducer,
  campusRelaysTabReducer,
  blockPageEditFormReducer,
  singleSignOnEditFormReducer,
  rowActionsReducer,
  loadingReducer,
  localDomainEditFormReducer,
  localForwardingEditFormReducer,
  networkEditFormReducer,
  relayEditFormReducer,
  subnetEditFormReducer,
  policyEditFormReducer,
  tldListsReducer,
  whiteBlackListReducer,
  whiteBlackListEditFormReducer,
  updateFirstAndLastNameReducer,
  insightsLogsReducer,
  responseDetailsReducer,
  relayBinariesListReducer,
  clientsManagerReducer,
  clientsConfigurerReducer,
  clientProfileEditFormReducer,
  clientProfileInstallFormReducer,
  clientBundleEditFormReducer,
  clientEditFormReducer,
  clientProfileLocalForwardingFormReducer,
  clientLocalForwardingEditFormReducer,
  clientSessionsReducer,
  filtersReducer,
  organizationEditFormReducer,
  organizationPersonasTabReducer,
  organizationProfilesTabReducer,
  organizationSitesTabReducer,
  organizationDashboardReducer,
  performanceStatsWidgetReducer,
  requestStatsWidgetReducer,
  threatStatsWidgetReducer,
  universalDashboardReducer,
  organizationSitesEditFormReducer,
  organizationPersonasEditFormReducer,
  organizationProfilesEditFormReducer,
  operatorOrganizationsTableReducer,
  usageBillingReducer,
  usersManagerReducer,
  personaEditFormReducer,
  personaReducer,
  requestsByCategoryWidgetReducer,
  userSessionsReducer,
  fallbackDNSSettingsSlice,
  fallbackDNSHostsSlice,
} from '../containers/index';
import {MANAGEMENT_TYPES} from '../constants';
import {getManagementType} from '../utils/tenants';

const reducerMap = {
  dashboardReducer: {
    [MANAGEMENT_TYPES.SCOUT_DNS]: scoutDnsDashboardReducer,
    [MANAGEMENT_TYPES.OEM]: oemDashboardReducer,
  },
  operatorLayoutReducer: {
    [MANAGEMENT_TYPES.SCOUT_DNS]: scoutDnsOperatorLayoutReducer,
    [MANAGEMENT_TYPES.OEM]: oemOperatorLayoutReducer,
  },
};

const managementType = getManagementType();
const selectReducer = (reducerName) => reducerMap[reducerName][managementType];

const rootReducer = combineReducers({
  i18n: i18nReducer,
  authReducer,
  campusEditFormReducer,
  campusLocalDomainsTabReducer,
  campusLocalForwardingTabReducer,
  campusNetworksTabReducer,
  campusSubnetsTabReducer,
  campusRelaysTabReducer,
  campusReducer,
  blockPageReducer,
  singleSignOnReducer,
  blockPageEditFormReducer,
  singleSignOnEditFormReducer,
  changePasswordReducer,
  clientsManagerReducer,
  clientsConfigurerReducer,
  clientEditFormReducer,
  clientProfileEditFormReducer,
  clientProfileInstallFormReducer,
  clientBundleEditFormReducer,
  clientProfileLocalForwardingFormReducer,
  clientLocalForwardingEditFormReducer,
  clientSessionsReducer,
  dashboardReducer: selectReducer('dashboardReducer'),
  filtersReducer,
  organizationsReducer,
  organizationEditFormReducer,
  organizationPersonasTabReducer,
  organizationProfilesTabReducer,
  organizationSitesTabReducer,
  organizationDashboardReducer,
  organizationSitesEditFormReducer,
  organizationPersonasEditFormReducer,
  organizationProfilesEditFormReducer,
  operatorOrganizationsTableReducer,
  networkReducer,
  rowActionsReducer,
  ipListReducer,
  loadingReducer,
  localDomainEditFormReducer,
  localForwardingEditFormReducer,
  logReducer,
  networkEditFormReducer,
  relayEditFormReducer,
  operatorLayoutReducer: selectReducer('operatorLayoutReducer'),
  personaReducer,
  policyReducer,
  personaEditFormReducer,
  policyEditFormReducer,
  subnetEditFormReducer,
  resetPasswordReducer,
  updatePasswordReducer,
  userAccountsReducer,
  userOrganizationsReducer,
  tldListsReducer,
  whiteBlackListReducer,
  whiteBlackListEditFormReducer,
  accessManagementReducer,
  apiAccessManagementReducer,
  updateFirstAndLastNameReducer,
  notificationsManagementReducer,
  subscriptionReducer,
  insightsReducer,
  insightsLogsReducer,
  responseDetailsReducer,
  relayBinariesListReducer,
  performanceStatsWidgetReducer,
  requestStatsWidgetReducer,
  threatStatsWidgetReducer,
  universalDashboardReducer,
  usageBillingReducer,
  usersManagerReducer,
  requestsByCategoryWidgetReducer,
  userSessionsReducer,
  [fallbackDNSSettingsSlice.name]: fallbackDNSSettingsSlice.reducer,
  [fallbackDNSHostsSlice.name]: fallbackDNSHostsSlice.reducer,
});

export default (state = {}, action) => {
  let newState = state;
  switch (action.type) {
    case authTypes.ACTION_LOGOUT_SUCCESS: {
      newState = {
        i18n: {
          ...state.i18n,
        },
      };
      break;
    }
    case userAccountsTypes.ACTION_SWITCH_ACCOUNT_SUCCESS: {
      newState = {
        i18n: {
          ...state.i18n,
        },
        userAccountsReducer: {
          ...state.userAccountsReducer,
        },
      };
      break;
    }
    default:
  }
  return rootReducer(newState, action);
};
