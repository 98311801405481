import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {STATES_ENTITY} from '../../constants';
import {ChangeEditModeAndState, FallbackDNSServer} from '../FallbackDNSSettings/types';

type InitialState = {
  isEditMode: boolean,
  fallbackDNSHostsState: string,
  entityValid: boolean,
  selectedServerIndex: number | null,
  selectedServer: FallbackDNSServer
}

export const FALLBACK_DNS_HOSTS_REDUCER = 'fallbackDNSHostsReducer';

const initialState: InitialState = {
  isEditMode: false,
  fallbackDNSHostsState: STATES_ENTITY.NONE,
  entityValid: false,
  selectedServerIndex: null,
  selectedServer: {
    address: '',
    port: '53',
  },
};

const fallbackDNSHostsSlice = createSlice({
  name: FALLBACK_DNS_HOSTS_REDUCER,
  initialState,
  reducers: {
    changeEditModeAndFallbackDNSHostsState(state, action: PayloadAction<ChangeEditModeAndState>) {
      state.isEditMode = action.payload.isEditMode;
      state.fallbackDNSHostsState = action.payload.state;
    },
    changeFallbackDNSHostsValid(state, action: PayloadAction<boolean>) {
      state.entityValid = action.payload;
    },
    setSelectedFallbackDNSHost(state, action: PayloadAction<FallbackDNSServer>) {
      state.selectedServer = action.payload;
    },
    setSelectedFallbackDNSHostWithIndex(state, action:
        PayloadAction<{server: FallbackDNSServer, index: number | null}>) {
      state.selectedServer = action.payload.server;
      state.selectedServerIndex = action.payload.index;
    },
  },
});

export const {changeEditModeAndFallbackDNSHostsState,
  changeFallbackDNSHostsValid,
  setSelectedFallbackDNSHost,
  setSelectedFallbackDNSHostWithIndex} = fallbackDNSHostsSlice.actions;

export default {name: FALLBACK_DNS_HOSTS_REDUCER, reducer: fallbackDNSHostsSlice.reducer};
