import React, {useEffect, useState} from 'react';
import {withStyles, WithStyles} from '@material-ui/core';
import {v4 as uuid} from 'uuid';
import {I18n} from 'react-redux-i18n';
import {useDispatch, useSelector} from 'react-redux';

import {CustomInput} from '../../material-dashboard-pro-react/components';

import {isPositiveInt, isValidIpAndCidrMask} from '../../utils/validators';
import {changeFallbackDNSHostsValid,
  FALLBACK_DNS_HOSTS_REDUCER,
  setSelectedFallbackDNSHost} from '../FallbackDNSHosts/slice';

import {style} from './style';

type FallbackDNSEditFormProps = WithStyles<typeof style> & {
}

const propsFormControl = {
  fullWidth: true,
};

function FallbackDNSEditForm({
  classes,
}: FallbackDNSEditFormProps) {
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    address: false,
    port: false,
  });

  const isEditMode = useSelector((store: any) => store[FALLBACK_DNS_HOSTS_REDUCER].isEditMode);
  const server = useSelector((store: any) => store[FALLBACK_DNS_HOSTS_REDUCER].selectedServer);

  const handleIpChange = (address: string) => {
    dispatch(setSelectedFallbackDNSHost({...server, address}));
    setErrors({...errors, address: !isValidIpAndCidrMask(address)});
  };

  const handlePortChange = (port: string) => {
    dispatch(setSelectedFallbackDNSHost({...server, port}));
    setErrors({...errors, port: !isPositiveInt(port)});
  };

  useEffect(() => {
    dispatch(changeFallbackDNSHostsValid(!errors.address && !errors.port));
  }, [errors]);

  return (
    <div className={classes.form}>
      <div className={classes.form__item}>
        <CustomInput
          errorText={
            errors.address && I18n.t('campusPage.fallbackDNSTab.errorMessages.addressNotValid')
          }
          error={errors.address}
          labelText={I18n.t('campusPage.fallbackDNSTab.servers.address')}
          id={uuid()}
          formControlProps={propsFormControl}
          inputProps={{
            disabled: !isEditMode,
            value: server.address,
            name: 'ipAddress',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => handleIpChange(e.target.value),
            type: 'text',
          }}
        />
      </div>
      <div className={classes.form__item}>
        <CustomInput
          errorText={
            errors.port && I18n.t('campusPage.fallbackDNSTab.errorMessages.portNotValid')
          }
          error={errors.port}
          labelText={I18n.t('campusPage.fallbackDNSTab.servers.port')}
          id={uuid()}
          formControlProps={propsFormControl}
          inputProps={{
            disabled: !isEditMode,
            value: server.port,
            name: 'port',
            onChange:
              (e: React.ChangeEvent<HTMLInputElement>) => handlePortChange(e.target.value),
            type: 'text',
          }}
        />
      </div>
    </div>
  );
}

export default withStyles(style)(FallbackDNSEditForm);
