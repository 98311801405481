import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {FallbackDNSServerCreating,
  FallbackDNSDetails,
  ContainerWithListAndForm,
  FallbackDNSTable} from '../../components';
import {FULL_WIDTH_MODE_TABLE,
  STATES_ENTITY,
  COMPRESSED_MODE_TABLE,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM} from '../../constants';

import {changeEditModeAndFallbackDNSHostsState,
  FALLBACK_DNS_HOSTS_REDUCER,
  setSelectedFallbackDNSHostWithIndex} from './slice';
import {FallbackDNSServer} from '../FallbackDNSSettings/types';
import {FALLBACK_DNS_SETTINGS_REDUCER,
  setFallbackDNSServers} from '../FallbackDNSSettings/slice';

export default function FallbackDNSHosts() {
  const dispatch = useDispatch();

  const [tableMode, setTableMode] = useState(FULL_WIDTH_MODE_TABLE);
  const [modeShowingContainer, setModeShowingContainer] =
    useState(MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN);

  const isDNSSettingsEditMode =
    useSelector((store: any) => store[FALLBACK_DNS_SETTINGS_REDUCER].isEditMode);
  const isEditMode = useSelector((store: any) => store[FALLBACK_DNS_HOSTS_REDUCER].isEditMode);
  const entityValid = useSelector((store: any) => store[FALLBACK_DNS_HOSTS_REDUCER].entityValid);

  const servers: FallbackDNSServer[] =
    useSelector((store: any) => store[FALLBACK_DNS_SETTINGS_REDUCER].servers);
  const selectedServerIndex =
    useSelector((store: any) => store[FALLBACK_DNS_HOSTS_REDUCER].selectedServerIndex);
  const selectedServer: FallbackDNSServer =
    useSelector((store: any) => store[FALLBACK_DNS_HOSTS_REDUCER].selectedServer);

  const handleServerEditSave = () => {
    dispatch(setFallbackDNSServers(servers.map((s, i) =>
      (i === selectedServerIndex ? selectedServer : s))));
    dispatch(changeEditModeAndFallbackDNSHostsState({
      isEditMode: false,
      state: STATES_ENTITY.NONE,
    }));
    setTableMode(FULL_WIDTH_MODE_TABLE);
    setModeShowingContainer(MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN);
  };

  const handleSaveNew = () => {
    dispatch(setFallbackDNSServers([...servers, selectedServer]));
    dispatch(changeEditModeAndFallbackDNSHostsState({
      isEditMode: false,
      state: STATES_ENTITY.NONE,
    }));
    setTableMode(FULL_WIDTH_MODE_TABLE);
    setModeShowingContainer(MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN);
  };

  const handleDelete = () => {
    dispatch(setFallbackDNSServers(servers.filter((s) =>
      !(s.address === selectedServer.address && s.port === selectedServer.port))));
    dispatch(changeEditModeAndFallbackDNSHostsState({
      isEditMode: false,
      state: STATES_ENTITY.NONE,
    }));
    setTableMode(FULL_WIDTH_MODE_TABLE);
    setModeShowingContainer(MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN);
  };

  const handleEdit = () => {
    dispatch(changeEditModeAndFallbackDNSHostsState({
      isEditMode: true,
      state: STATES_ENTITY.EDITING,
    }));
  };

  const handleCancel = () => {
    dispatch(changeEditModeAndFallbackDNSHostsState({
      isEditMode: false,
      state: STATES_ENTITY.CANCELED,
    }));
    setTableMode(FULL_WIDTH_MODE_TABLE);
    setModeShowingContainer(MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN);
  };

  const showCreatingForm = () => {
    dispatch(changeEditModeAndFallbackDNSHostsState({
      isEditMode: true,
      state: STATES_ENTITY.CREATING,
    }));
    dispatch(setSelectedFallbackDNSHostWithIndex({server: {address: '', port: '53'}, index: null}));
    setTableMode(COMPRESSED_MODE_TABLE);
    setModeShowingContainer(MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM);
  };

  const showDetails = (index: number) => {
    dispatch(setSelectedFallbackDNSHostWithIndex({server: servers[index], index}));
    setTableMode(COMPRESSED_MODE_TABLE);
    setModeShowingContainer(
      MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS,
    );
  };

  const getFormItemByModeShowing = (modeShowingContainer: string) => {
    let containerItem = null;
    switch (modeShowingContainer) {
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN:
        containerItem = null;
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM:
        containerItem = (
          <FallbackDNSServerCreating
            onCancel={handleCancel}
            onSave={handleSaveNew}
            isValid={entityValid}
          />
        );
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS:
        containerItem = (
          <FallbackDNSDetails
            onBack={handleCancel}
            onCancel={handleCancel}
            onDelete={handleDelete}
            onEdit={handleEdit}
            onSave={handleServerEditSave}
            isEditMode={isEditMode}
            isValid={entityValid}
          />
        );
        break;
      default:
        break;
    }
    return containerItem;
  };
  return (
    <ContainerWithListAndForm
      list={(
        <FallbackDNSTable
          data={servers}
          mode={tableMode}
          isEditMode={isEditMode}
          selectedIndex={selectedServerIndex}
          handleClickButtonNew={showCreatingForm}
          handleRowClick={showDetails}
          showAddNew={isDNSSettingsEditMode}
        />
      )}
      form={getFormItemByModeShowing(modeShowingContainer)}
    />
  );
}
