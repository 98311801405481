const primaryColor = '#303036';
const secondaryColor = '#c80815';
const editColor = '#ff9800';
const disabledColor = 'rgba(0, 0, 0, 0.23)';
const linkColor = '#005cff';

const bgColor = '#F4F5F6';
const bgGreenColor = '#F0F8F3';
const bgRedColor = '#FDF7F8';

const blackColor = '#303036';
const grayColor = '#999999';
const greenColor = '#4caf50';
const redColor = '#c80815';
const lightRedColor = '#ea3b1c';
const yellowColor = '#ff9800';
const blueColor = '#00bcd4';
const whiteColor = '#eeeeee';
const orangeColor = '#ff6600';

const darkBlueColor = '#2762ef';
const lightBlueColor = '#00b0ff';
const brightRedColor = '#f40000';
const darkerGreenColor = '#088b3a';
const yellowOtherColor = '#fb8a00';

const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 300,
  lineHeight: '1.5em',
};

const editModeDisabledStyle = {
  pointerEvents: 'none',
  opacity: '0.8',
};

const headerHeight = '55px';
const sidebarWidth = '170px';
const sidebarMiniWidth = '80px';
const sidebarRightWidth = '200px';

export {
  primaryColor,
  secondaryColor,
  disabledColor,
  editColor,
  linkColor,
  lightRedColor,

  bgColor,
  bgGreenColor,
  bgRedColor,

  blackColor,
  blueColor,
  grayColor,
  greenColor,
  orangeColor,
  redColor,
  yellowColor,
  whiteColor,

  darkBlueColor,
  lightBlueColor,
  brightRedColor,
  darkerGreenColor,
  yellowOtherColor,

  defaultFont,

  headerHeight,
  sidebarWidth,
  sidebarMiniWidth,
  sidebarRightWidth,

  editModeDisabledStyle,
};
