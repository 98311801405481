import {Dispatch} from '@reduxjs/toolkit';
import {get, makeUrl, post} from '../../utils/fetcher';
import {METHODS} from '../../constants';
import {FallbackDNSSettings} from './types';
import {setFallbackDNSSettings, setUpstreamServices} from './slice';

export function saveFallbackDNS(data: FallbackDNSSettings) {
  const url = makeUrl(METHODS.UPDATE_RELAY_FALLBACK_DNS);
  return (dispatch: Dispatch) => post(url, data, dispatch);
}

export function getFallbackDnsSettings(locationId: string) {
  const queryParams = {locationId};
  const url = makeUrl(METHODS.GET_FALLBACK_DNS_SETTINGS, queryParams);
  return (dispatch: Dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        dispatch(setFallbackDNSSettings(response.data));
      }
    });
}

export function getUpstreamServices() {
  const url = makeUrl(METHODS.GET_UPSTREAM_SERVICES);
  return (dispatch: Dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        dispatch(setUpstreamServices(response.data.services));
      }
    });
}
