import React from 'react';
import {I18n} from 'react-redux-i18n';

import {FallbackDNSServer} from '../../containers/FallbackDNSSettings/types';
import EntitiesListWithViewModesCard
  from '../EntitiesListWithViewModesCard/EntitiesListWithViewModesCard';
import {ACCESS_RIGHTS} from '../../constants';

type FallbackDNSTableProps = {
  data: FallbackDNSServer[],
  isEditMode: boolean,
  selectedIndex: number,
  mode: string,
  handleClickButtonNew: () => void,
  // eslint-disable-next-line no-unused-vars
  handleRowClick: (index: number) => void,
  showAddNew?: boolean,
};

const tableStyles = {
  minHeight: '300px',
  height: 'auto',
  maxHeight: '800px',
};

const rightAvailabilityMap = {
  buttonCreate: [ACCESS_RIGHTS.SITE_FALLBACK_CREATE],
};

export function FallbackDNSTable({data,
  isEditMode,
  selectedIndex,
  mode,
  handleClickButtonNew,
  handleRowClick,
  showAddNew = true}: FallbackDNSTableProps) {
  const headersCompressed = [
    {
      Header: I18n.t('campusPage.fallbackDNSTab.servers.address'),
      accessor: 'address',
      filterable: false,
    },
  ];

  const headersFullWidth = [
    ...headersCompressed,
    {
      Header: I18n.t('campusPage.fallbackDNSTab.servers.port'),
      accessor: 'port',
      filterable: false,
      width: 200,
    },
  ];

  return (
    <EntitiesListWithViewModesCard
      mode={mode}
      headersFullWidthMode={headersFullWidth}
      headersCompressedMode={headersCompressed}
      tableData={data}
      disabled={isEditMode}
      title={I18n.t('campusPage.fallbackDNSTab.servers.title')}
      entityType={I18n.t('entitiesTypes.fallbackDNS')}
      tableStyles={tableStyles}
      selectedEntityIndex={selectedIndex}
      handleClickButtonNew={showAddNew ? handleClickButtonNew : null}
      handleClickRow={handleRowClick}
      accessRights={rightAvailabilityMap}
      customCardClass="entityWithModesCard_fallbackDNSHosts"
    />
  );
}
