import {createStyles} from '@material-ui/core';

export const style = createStyles({
  upstreamContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  upstreamContainer_item__dropdown: {
    maxWidth: '200px',
    width: '100%',
    paddingLeft: '12px',
  },
  upstreamContainer_item__switch: {
    marginBottom: '12px',
  },
});
